import { useEffect, useState } from "react";
import Papa from "papaparse";
import { item } from "./types";
import DrinksListItem from "./DrinksListItem";
import { Accordion } from "react-bootstrap";

type props = {
  items: item[];
  src: string;
  accoridanKey: string;
  name: string;
};

const DynamicDrinksList = (props: props) => {
  return (
    <Accordion.Item
      eventKey={props.accoridanKey}
      style={{ backgroundColor: "#1d1d1a" }}
    >
      <Accordion.Header style={{ backgroundColor: "#1d1d1a" }}>
        {props.name}
      </Accordion.Header>
      <Accordion.Body>
        {
          props.items.map((cask, i) => {
            return <DrinksListItem drink={cask} key={i} />;
          })
        }
      </Accordion.Body>
    </Accordion.Item>
  );
};

export default DynamicDrinksList;
