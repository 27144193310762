import { Accordion } from "react-bootstrap";
import DynamicDrinksList from "./DynamicDrinkList";
import { item } from "./types";
import { useEffect, useState } from "react";
import Papa from "papaparse";

const convertToDrinkArr = (parsedResult: Papa.ParseResult<any>): item[] => {
  let result: item[] = [];
  parsedResult.data.shift();
  parsedResult.data.map((row) => {
    let item: item = {
      menuNum: row[0],
      title: row[1],
      sponsor: row[2],
      notes: row[3],
      desc: row[4],
      brewery: row[5],
      abv: row[6],
      type: row[7],
      location: row[8],
    };
    result.push(item);
  });
  return result;
};

const DrinksList = () => {
  const [items, setItems] = useState<item[]>([]);

  const [downstairsBar, setDownstairsBar] = useState<item[]>([]);
  const [upstairsBar, setUpstairsBar] = useState<item[]>([]);


  useEffect(() => {
    fetch("data/items.csv")
      .then((response) => response.text())
      .then((responseText) => {
        const parsedData = Papa.parse<any>(responseText);
        let items = convertToDrinkArr(parsedData);
        setDownstairsBar(items.filter((item) => item.location == "Downstairs Beers"));
        setUpstairsBar(items.filter((item) => item.location == "Upstairs Beers"))
        setItems(items);
      });
  }, []);
  return (
    <div>
      <h2 style={{ textAlign: "center" }}>Drinks List</h2>
      <Accordion flush alwaysOpen>
        <DynamicDrinksList
          items={downstairsBar}
          name="Downstairs Bar"
          src="data/casks.csv"
          accoridanKey="0"
        />
        <DynamicDrinksList items={upstairsBar} name="Upstairs Bar" src="data/kegs.csv" accoridanKey="1" />
        <DynamicDrinksList
          items={[]}
          name="Spirits Bar"
          src="data/other.csv"
          accoridanKey="2"
        />
      </Accordion>
    </div>
  );
};

export default DrinksList;
